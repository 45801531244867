<template>
   <main>
       <div class="pb">
            <Button icon="md-return-left" shape="circle" @click="$router.back()">返回</Button>
        </div>

        <Form ref="form" :model="form" :rules="validate" label-position="top">
            <Divider orientation="left">基本信息</Divider>
            <Row>
                <Col :span="9" :offset="3">
                    <FormItem label="名称" prop="title">
                        <Input :maxlength="45" show-word-limit v-model="form.title" class="w50" />
                    </FormItem>
                    <FormItem label="排序权重">
                        <InputNumber :min="-32768" :max="32767" class="input-number-long" v-model="form.weight" />
                    </FormItem>
                    <FormItem label="状态">
                        <i-switch v-model="form.status" size="large" :true-value="1" :false-value="0">
                            <span slot="open">启用</span>
                            <span slot="close">停用</span>
                        </i-switch>
                    </FormItem>
                </Col>
            </Row>

            <Divider orientation="left">权限设置</Divider>
            <Row>
                <Col :span="9" :offset="3">
                    <Tree ref="actions" :data="actions" show-checkbox check-directly></Tree>
                </Col>
            </Row>

            <Divider />
            <Row>
                <Col :span="9" :offset="3">
                    <FormItem>
                        <Button type="primary" icon="md-add-circle" @click="submit" v-if="!id">确认添加</Button>
                        <Button type="success" icon="md-filing"  @click="submit" v-else>保存修改</Button>
                    </FormItem>
                </Col>
            </Row>
        </Form>
   </main>
</template>

<script>
    export default {
        data()
        {
            return {
                id  : this.$route.params.id,
                form: {
                    title : '',
                    weight: 0,
                    status: 1,
                    access: []
                },
                validate: {
                    name: [
                        { required: true, message: '请输入名称', trigger: 'blur' }
                    ]
                },
                actions: []
            }
        },
        created()
        {
            this.id ? this.loadData() : this.loadAuthActions()
        },
        methods: {
            submit()
            {
                var checkeds = this.$refs.actions.getCheckedNodes()
                var access = []
                
                for(var item of checkeds)
                    access.push(item.id)
                
                this.form.access = access

                this.$refs.form.validate((valid) => {
                    if (valid)
                    {
                        const url = '/authRole/' + (this.id ? 'update/id/' + this.id : 'create')
                        this.$http.post(url, this.form)
                            .then(res => {
                                this.$router.back()
                            })
                    }
                })
            },
            loadData()
            {
                this.$http.get('/authRole/detail/id/' + this.id)
                    .then(res => {
                        this.form = res
                        this.loadAuthActions()
                    })
            },
            loadAuthActions()
            {
                this.$http.get('/authAction/list', {params: { status: 1 }})
                    .then(res => {
                        for (var c of res)
						{
							for (var a of c.children)
								if (this.form.access.indexOf(a.id) !== -1)
									a.checked = true

							if (this.form.access.indexOf(c.id) !== -1)
								c.checked = true
						}

                        this.actions = res
                    })
            }
        }
    }
</script>